const config = {
  // 假用户信息
  userInfo: {
    nickName: '亚甲基蓝',
    avatarUrl: 'https://foruda.gitee.com/avatar/1677108189327206088/8027527_pigeoner_1636725944.png!avatar200',
    openId: '666'
  },
  isLogin: false,
  questionCount: 10, // 每次答题的题目数量
  questionScore: 10, // 每题的分数
  baseUrl: 'https://software.mcl913.top',
  baseImgUrl: 'https://cuc-mandarin-oss-image.oss-cn-beijing.aliyuncs.com',
  baseVideoUrl: 'https://cuc-mandarin-oss-video.oss-cn-beijing.aliyuncs.com'
};
export default config;
