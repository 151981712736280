import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import config from './common/js/config';
import axios from 'axios';
import './common/css/common.css';
// import MockAdapter from 'axios-mock-adapter';
// import './mock' ;
// import loading from './components/loading.vue';
import LoadingPlugin from './components/toast-plugin';
import 'amfe-flexible'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

Vue.use(LoadingPlugin);

// Vue.component('loading', loading);
Vue.config.productionTip = false;
Vue.prototype.$config = config;
Vue.prototype.$http = axios;
axios.defaults.baseURL = '/api';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
