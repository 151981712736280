import Vue from 'vue';
import loading from './toast.vue'; // loading 样式

let $app = Vue.extend(loading); // 在 vue 中注册loading组件
let $toast = new $app().$mount(document.createElement('div'));
// 创建 loading 实例并挂载 vue 实例上
document.body.appendChild($toast.$el);

export default {
  install(vm) {
    vm.prototype.$toast = {
      loading: options => {
        Object.keys(options).forEach(item => {
          $toast[item] = options[item];
        });
        $toast.isShow = true;
      },
      clear: () => {
        $toast.isShow = false;
      },
      alert: options => {
        const { message, duration = 2000 } = options;
        $toast.message = message;
        $toast.isShow = true;
        $toast.isLoading = false;
        setTimeout(() => {
          $toast.isShow = false;
        }, duration);
      }
    };
  }
};
