<template>
  <el-row :gutter="20" style="background-color: black; height: 100vh;">
    <!-- 左边黑色背景 -->
    <el-col :span="6" style="background-color: black;">
      <div style="height: 100%;"></div>
    </el-col>

    <!-- 中间内容部分 -->
    <el-col :span="24" style="background-color: #fff;">
      <div id="app" style="height: 100vh;">
        <transition :name="transitionName" mode="out-in">
          <router-view/>
        </transition>
      </div>
    </el-col>

    <!-- 右边黑色背景 -->
    <el-col :span="6" style="background-color: black;">
      <div style="height: 100%;"></div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      transitionName: 'fade' // 可以设置为 'fade', 'zoom', 'slide'
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%; /* 修改为 100% 来覆盖父元素 */
}

/* Fade */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Zoom */
.zoom-enter-active, .zoom-leave-active {
  transition: transform 0.5s ease;
}

.zoom-enter, .zoom-leave-to {
  transform: scale(0.9);
}

/* Slide */
.slide-enter-active, .slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter, .slide-leave-to {
  transform: translateX(100%);
}

/* Additional styles for better visual effect */
.fade-enter-active, .zoom-enter-active, .slide-enter-active {
  position: absolute;
  width: 100%;
}
</style>
