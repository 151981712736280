import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/history.vue')
  },
  // rank
  {
    path: '/rank',
    name: 'rank',
    component: () => import('../views/rank.vue')
  },
  // result
  {
    path: '/result',
    name: 'result',
    component: () => import('../views/result.vue')
  },
  // test
  {
    path: '/test/:type',
    name: 'test',
    component: () => import('../views/test.vue')
  },
  //test-index
  // {
  //   path: '/test-index',
  //   name: 'test-index',
  //   component: () => import('../views/test-index.vue')
  // }

];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

export default router;
