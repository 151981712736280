<template>
  <div class="loading"
       v-show="isShow">
    <div class="load-box" :class="!isLoading ? 'alert-box': ''">
      <!-- 图片放在文末，自行右键另存为 -->
      <div class="sk-circle" v-show="isLoading">
        <div v-for="i in 12" :class="'sk-child sk-circle' + i"></div>
      </div>
      <span>{{ message }}</span>
    </div>
  </div>
</template>
 
<script>
export default {
  data () {
    return {
      message: '加载中...',
      isShow: false,
      isLoading: true
    }
  }
}
</script>
<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .load-box {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.8px;
    font-size: 13px;
  }
  .alert-box {
    height: 50px;
  }
}
.sk-circle {
  margin: 0.5rem auto;
  width: 40px;
  height: 40px;
  position: relative;
  .sk-child {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
    &::before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: #fff;
      border-radius: 100%;
      -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    }
  }
}
@for $i from 2 through 12 {
  .sk-circle .sk-circle#{$i} {
    -webkit-transform: rotate(($i - 1)*30deg);
    -ms-transform: rotate(($i - 1)*30deg);
    transform: rotate(($i - 1)*30deg);
    &::before {
      -webkit-animation-delay: -#{(13 - $i) * 0.1}s;
              animation-delay: -#{(13 - $i) * 0.1}s;
    }
  }
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>